/* Table Action Dropdown */
.action-btn{
    width: 16px !important;
    height: 30px !important;
}
.action-btn:after{
    display: none !important;
}
.dropright-mr{
    margin: -33px 0px 0 20px !important;
}
/* Table Style */
.responsive-card-table {
border: 0;
border-collapse: collapse;
margin: 0;
padding: 0;
width: 100%;
table-layout: fixed;
}
.responsive-card-table caption {
text-align: left;
font-size: 1.3em;
margin: 0.5em 0 0.75em;
}
.responsive-card-table thead {
display: none;
}
.responsive-card-table tr {
display: block;
border: 1px solid #ddd;
padding: 1em 1em 0.5em;
background-color: #fff;
}
.responsive-card-table tr + tr {
margin-top: 0.625em;
}
.responsive-card-table td {
display: flex;
justify-content: space-between;
align-items: flex-end;
border-bottom: 1px solid #ddd;
font-size: 0.9em;
line-height: 1.35em;
}
.responsive-card-table td:before {
content: attr(data-label);
font-size: 0.9em;
text-align: left;
font-weight: bold;
text-transform: uppercase;
max-width: 45%;
color: #545454;
}
.responsive-card-table td + td {
margin-top: 0.8em;
}
.responsive-card-table td:last-child {
border-bottom: 0;
}
@media screen and (min-width: 800px) {
.responsive-card-table {
    background: #fff;
    border: 1px solid #c6c6c6;
    border-radius: 10px !important;
    /* overflow: hidden; */
}
.responsive-card-table th{
    background-color: #F3F6F9;
}
.responsive-card-table th:first-child, .responsive-card-table td:first-child{
    padding-left: 15px;
} 
.responsive-card-table th:first-child{
    border-top-left-radius: 10px;
}
.responsive-card-table th:last-child{
    border-top-right-radius: 10px;
}
.responsive-card-table tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}
.responsive-card-table tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}
.responsive-card-table caption {
   font-size: 1.8em;
}
.responsive-card-table thead {
   display: table-header-group;
}
.responsive-card-table tr {
   display: table-row;
   border: 0;
}
.responsive-card-table th, .responsive-card-table td {
   /* text-align: center; */
}
.responsive-card-table th {
   font-size: 0.75em;
   text-transform: uppercase;
   font-weight: 800;
}
.responsive-card-table td {
   display: table-cell;
}
.responsive-card-table td:before {
   display: none;
}
.responsive-card-table td:last-child {
   border-bottom: 1px solid #ddd;
}
}

@media screen and (max-width: 800px) {
    .responsive-card-table td, th {
        border: 0;
    }
    .responsive-card-table td, th {
        border-bottom: 1px solid #ddd;
    }
    .responsive-card-table tr {
        border-radius: 8px;
    }
}