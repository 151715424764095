
/* **************** Global Path **************** */
@import './global/variable.css';
@import './global/bootstrap.css';

/* **************** Component Path **************** */
@import './component/navbar.css';
@import './component/responsive-table.css';


/* **************** Font Awesom Icon **************** */
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* **************** Font Family **************** */
@import './font/urbane/font.css';
@import './font/acumin-pro-cufonfonts-webfont/font.css';

/* **************** Font Family **************** */
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";



/* ****************  **************** */
body, html{
    position: relative;
    height: auto;
    width: 100%;
    /* overflow-x: hidden; */
    scroll-behavior: smooth;
}