.aside-nav{
    height: 100vh;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 10;
}
.aside-container{
    position: relative;
    width: calc(100% - 250px);
    left: 250px;
}
.aside-nav-toggle{
    width: 67px;
}
.aside-nav-toggle .textnone, .aside-nav-toggle .collapse-menu .accordion-button::after{
    display: none !important;
}
.aside-nav-toggle:hover{
    width: 250px;
    position: absolute;
}
.aside-nav-toggle:hover .textnone, .aside-nav-toggle:hover .collapse-menu .accordion-button::after{
    display: block !important;
}
.aside-container-toggle{
    width: calc(100% - 67px);
    left: 67px;

}
.menu-logo{
    width: 100%;
    height: 100px;
    border-bottom: 1px dashed #807e7e;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aside-nav-menu-list {
    margin-top: 30px;
    margin-left: 0;
    padding: 0;
}

.aside-nav-menu-list li {
    border-radius: 6px;
    overflow: hidden;
}
.aside-nav-menu-list li a{
    color: #fff;  
    padding: 10px 17px; 
    width: 100%;
    display: block;
}

.custom-accordion-button {
    position: relative;
}
.collapse-menu ngb-accordion .accordion-item, .collapse-menu ngb-accordion{
    border: none;
    box-sizing: none;
    border-radius: 0;
    background-color: #000;
}
.collapse-menu  ngb-accordion .accordion-item .accordion-button{
    padding: 10px 17px;
    background-color: #000;
    color: #fff ;
    border-radius: 0;
    box-shadow: none;
}
.collapse-menu  ngb-accordion .accordion-item .accordion-button:hover{
    background: rgba(20, 192, 254, 0.3)
}
.collapse-menu  ngb-accordion .accordion-body{
    background-color: #000;
    padding: 0;
}
.collapse-menu  .accordion-button::after {
    background-image: url(../../media/icon/arrow-white-down.svg);
    background-size: 0.85rem;
    height: 0.85rem;
    width: 0.85rem;
}
.aside-nav-menu-list li:hover {
    background: rgba(20, 192, 254, 0.3)
}
.sidebar_mobile_toggle-btn{
    display: none !important;
}
@media (max-width:991.98px) {
    .collapse-btn{
        display: none !important;
    }
    .aside-nav{
        display: none;
        animation: slide-left 700ms ease;
    }
    .aside-container{
        width: 100%;
        left: 0;
    }
    .mobile-overlay{
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9;
        display: none;
    }
    .sidebar_mobile_toggle-btn{
        display: block !important;
    }
    .mobile-toggle{
        display: block;
    }
    .mobile-toggle-nav.aside-nav{
        display: block;
        width: 250px;
        position: absolute;
    }
}
@keyframes slide-left {
    from {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

